.button {
  font: inherit;
  border: 1px solid #087f5b;
  background: #20c997;
  color: white;
  padding: 0.75rem 3.5rem;
  cursor: pointer;
  font-size: 1.15rem;
  border-radius: 30px;
}

.back-btn {
  font: inherit;
  border: 1px solid #334e68;
  background: #9fb3c8;
  color: white;
  padding: 0.5rem 3.5rem !important;
  cursor: pointer;
  font-size: 1.15rem;
  border-radius: 30px;
}

.button:hover,
.button:active {
  background: #12b886;
  border-color: #087f5b;
}

.button:focus {
  outline: none;
}

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed;
}
