.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav li {
  margin: 0;
  margin-left: 1.1rem;
}

.nav a {
  text-decoration: none;
  color: white;
}

.nav a:hover,
.nav a:active {
  color: #f3cafb;
}

@media only screen and (max-width: 420px) {
  .nav li {
    margin-left: 10px;
  }
}
