@font-face {
  font-family: Lato;
  src: url("fonts/Lato/Lato-Bold.ttf");
}
@font-face {
  font-family: OpenSans;
  src: url("fonts/Open_Sans/OpenSans-Bold.ttf");
}

body {
  background-color: #fff;
}
h1 {
  text-align: center;
  color: #38d9a9;
  font-size: 50px;
  margin: 0px 0 25px 0;
  user-select: none;
  font-family: Lato, helvetica;
}

img {
  display: block;
  margin: 0 auto;
  width: 380px;
  height: 380px;
}

.landing-img {
  width: 430px;
  height: 340px;
}

footer {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: -15px;
  background-color: #eafff8;
}

.credit {
  text-decoration: none;
  color: white;
}

.register-btn {
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
  background: #bcccdc;
  border-color: #334e68;
}

.register-btn:hover,
.register-btn:active {
  background: #9fb3c8;

  border-color: #334e68;
}

.register-btn:focus {
  outline: none;
}

.register-btn:disabled,
.register-btn:focus:disabled,
.register-btn:hover:disabled,
.register-btn:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed;
}

.login-btn {
  display: block;
  margin: 0 auto;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  font-family: OpenSans, sans-serif;
}

.back-btn {
  background: none;
  border-color: #adb5bd;
  color: #adb5bd;
  margin-right: 20px;
}

.back-btn:hover,
.back-btn:active {
  background: none;
  border-color: #adb5bd;
  color: #adb5bd;
}

.back-btn:focus {
  outline: none;
}

.back-btn:disabled,
.back-btn:focus:disabled,
.back-btn:hover:disabled,
.back-btn:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed;
}

.action-buttons-container {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #e6fcf5;
  padding: 25px;
  border-radius: 10px;
}

.decription {
  font-weight: 600;
  color: #464646;
  margin: 0 auto;
  margin-bottom: 25px;
  margin-top: 15px;
  user-select: none;
  font-family: OpenSans, sans-serif;
  margin-right: 16px;
}

.decription:last-child {
  margin-right: 0px;
}

.landing-flex {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #eafff8;
}

.description-flex {
  display: flex;
}

@media screen and (min-width: 1380px) {
  .landing-container h1 {
    font-size: 64px;
  }

  .landing-container img {
    width: 510px;
    height: 380px;
  }

  .description-flex {
    width: 680px;
  }

  .decription {
    font-size: 22px;
  }

  .login-btn {
    font-size: 22px;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .landing-img {
    width: 312px;
    height: 270px;
  }
  .landing-flex {
    align-items: center;
    margin: 0 auto;
  }
  .description-flex {
    display: block;
    width: 180px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 24px;
  }

  .description-flex p {
    margin-bottom: 0px;
    margin-top: 6px;
    width: 200px;
  }

  h1 {
    font-size: 36px;
    margin-left: 15px;
  }

  .landing-container h1 {
    font-size: 48px;
  }

  .header-links {
    width: 100%;
  }

  .link-btn {
    font-size: 16px;
  }

  .action-buttons-container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }

  .reg-submit button {
    padding: 10px 20px;
    font-size: 16px;
  }
}
