.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.backdrop:hover {
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 2vh;
  left: 30%;
  width: 40%;
  z-index: 100;
  overflow-y: scroll;
  max-height: 100%;
  overflow-x: hidden;
}

.modal h2 {
  user-select: none;
}

.header {
  background: #4f005f;
  padding: 1rem;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 1rem;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.add-member-btn {
  background-color: #4f005f;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .modal {
    width: 90%;
    left: 5%;
  }
}

@media only screen and (min-width: 768px) {
  .modal {
    left: 25%;
    width: 50%;
  }
}
