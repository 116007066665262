.login {
  width: 90%;
  max-width: 40rem;
}

h2 {
  text-align: center;
  color: #38d9a9;
  font-size: 28px;
  margin-bottom: 25px;
  user-select: none;
}

.actions {
  text-align: center;
}

span {
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

span.memberName {
  display: inline !important;
}

@media only screen and (max-width: 420px) {
  .login {
    width: 94%;
  }
  h2 {
    margin-top: 20px;
  }
}
