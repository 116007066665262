.control {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.control label,
.control input {
  display: block;
}

.control label {
  font-weight: bold;
  flex: 1;
  color: #464646;
  margin-bottom: 0.5rem;
}

.control input {
  flex: 3;
  font: inherit;
  padding: 0.35rem 0.35rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin: 8px 0;
}

.control input:focus {
  outline: none;
  border-color: #15cd95;
  background: #e6fcf5;
}

.control.invalid input {
  border-color: red;
  background: #fbdada;
}

@media (min-width: 768px) {
  .control {
    align-items: center;
    flex-direction: row;
  }
}

@media only screen and (max-width: 420px) {
  .control label {
    margin-bottom: 0;
  }

  .control input {
    margin-bottom: 12px;
  }
}
