.main-header {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #38d9a9;
  padding: 0 2rem;
  margin-bottom: 0;
  border: none;
  outline: none;
  z-index: 100;
  border-bottom: 3px solid #00b37d;
}

.main-header h1 {
  color: white;
  margin-top: 25px;
  margin-bottom: 25px;
  user-select: none;
}

.main-header a {
  text-decoration: none;
  color: black;
  width: 100%;
  display: block;
}

.header-content {
  max-width: 1000px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .main-header {
    padding: 0;
  }

  .main-header h1 {
    font-size: 34px;
  }

  .tasks-container {
    width: 100%;
  }

  img {
    width: 280px;
    height: 310px;
  }

  .tasks-display {
    margin: 20px 8px;
  }

  .new-chore-btn {
    width: 100%;
  }
}
